import { useKeycloak } from '@react-keycloak/web';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loading } from '@components/Loading/Loading';

import { ROUTES } from '@constants/routes';

export type PrivateRouteProps = {
  element: ReactElement;
  requiredAccess?: string;
};

export const PrivateRoute = ({ element, requiredAccess }: PrivateRouteProps) => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  const [readyToRedirect, setReadyToRedirect] = useState(false);

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login();
    } else if (initialized && keycloak.authenticated) {
      if (!keycloak.tokenParsed?.assignedGroups) {
        location.reload();
      }
      if (requiredAccess && !(keycloak.tokenParsed?.assignedGroups as string[]).includes(requiredAccess)) {
        navigate(ROUTES.MAIN);
      } else {
        setReadyToRedirect(true);
      }
    }
  }, [initialized, keycloak, navigate, requiredAccess]);

  if (!initialized) {
    return (
      <Loading
        isLoading={true}
        sx={{ backgroundColor: '#FFF' }}
      />
    );
  }

  return readyToRedirect ? element : null;
};
