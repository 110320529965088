import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject, from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';

import { createErrorLink } from './helpers/errorLink';
import { CreateApolloClientProps } from './interface';

export const createApolloClient = (props: CreateApolloClientProps<NormalizedCacheObject>) => {
  const {
    // uri,
    cache,
    headers,
    showErrorHandler,
    unauthorizedCallback,
    refreshRetryCallback,
    link,
    ...rest
  } = props;

  const errorLink = createErrorLink({
    showErrorHandler,
    unauthorizedCallback,
    refreshRetryCallback,
  });

  // const contextLink = createContextLink(headers);

  const contextLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: typeof headers === 'function' ? headers() : headers,
    });
    return forward(operation);
  });

  const retryLink = new RetryLink();

  return new ApolloClient({
    cache: new InMemoryCache(cache),
    link: from([retryLink, errorLink, contextLink, link!]),
    credentials: 'include',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
    ...rest,
  });
};
