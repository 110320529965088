import { ROUTES } from '@constants/routes';

export const modules = [
  {
    title: 'Пассажиропоток',
    description:
      'Моделирование и прогнозирование пассажиропотоков в разных видах транспорта и сценариев инфраструктурных проектов.',
    path: ROUTES.PASSENGER_TRAFFIC,
    id: 'passenger-traffic',
  },
  {
    title: 'Агломерация',
    description:
      'Взаимоувязка проектов развития железнодорожного транспорта с городскими транспортными системами.',
    path: ROUTES.AGGLOMERATION,
    id: 'agglomeration',
  },
  {
    title: 'ТПУ',
    description: 'Расчет оптимальных параметров транспортных пересадочных узлов',
    path: ROUTES.TPU,
    id: 'station',
  },
];
