import { buttonClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { palette } from '@system/theme/palette';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.pxToRem(14),
      boxShadow: 'none',
      textTransform: 'none',
      padding: theme.spacing(1.75, 2),

      [`&.${buttonClasses.textPrimary}`]: {
        padding: 0,
        height: 'auto',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        [`&.${buttonClasses.disabled}`]: {
          color: palette.text.dark,
        },
      },
    }),
    containedPrimary: {
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: palette.primary.main,
        color: palette.text.light,
      },
    },
    containedSecondary: {
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: palette.secondary.main,
        color: palette.text.light,
      },
    },
    outlinedPrimary: {
      borderColor: palette.primary.main,
      backgroundColor: palette.common.white,
      '&:hover': {
        borderColor: palette.primary.dark,
        color: palette.primary.dark,
      },
      '&:disabled': {
        borderColor: palette.primary.main,
        color: palette.text.dark,
      },
    },
    sizeMedium: ({ theme }) => ({
      height: theme.typography.pxToRem(44),
    }),
    sizeSmall: ({ theme }) => ({
      height: theme.typography.pxToRem(32),
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(1, 2),
    }),
  },
};
