import { ReactNode, createContext, useContext, useState } from 'react';

export const NavigationBlockContext = createContext<{
  blockNavigation: boolean;
  setBlockNavigation: (payload: boolean) => void;
}>({
  blockNavigation: false,
  setBlockNavigation: () => {},
});

export const useBlockNavigation = () => useContext(NavigationBlockContext);

export const NavigationBlockProvider = ({ children }: { children: ReactNode }) => {
  const [blockNavigation, setBlockNavigation] = useState(false);

  return (
    <NavigationBlockContext.Provider value={{ blockNavigation, setBlockNavigation }}>
      {children}
    </NavigationBlockContext.Provider>
  );
};
