import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { Layout } from '@components/layout';

import { ACCESS_GROUPS } from '@constants/accessGroups';
import { ROUTES } from '@constants/routes';

import { PrivateRoute } from '@system/routing/PrivateRoute';

const PageAgglomeration = lazy(() => import('@pages/agglomeration'));
const ErrorPage = lazy(() => import('@pages/error'));
const PageModules = lazy(() => import('@pages/modules'));
const PagePassengerTraffic = lazy(() => import('@pages/passenger-traffic'));
const PageTPU = lazy(() => import('@pages/tpu'));
const UIKit = lazy(() => import('@pages/ui-kit'));

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: (
      <Suspense>
        <ErrorPage />
      </Suspense>
    ),
    children: [
      {
        path: `${ROUTES.MAIN}/*`,
        element: (
          <PrivateRoute
            element={
              <Suspense>
                <PageModules />
              </Suspense>
            }
          />
        ),
      },
      {
        path: `${ROUTES.GENERAL}/*`,
        element: (
          <PrivateRoute
            element={
              <Suspense>
                <PageModules />
              </Suspense>
            }
          />
        ),
      },
      {
        path: `${ROUTES.PASSENGER_TRAFFIC}/*`,
        element: (
          <PrivateRoute
            element={
              <Suspense>
                <PagePassengerTraffic />
              </Suspense>
            }
            requiredAccess={ACCESS_GROUPS.PASS_FLOW}
          />
        ),
      },
      {
        path: `${ROUTES.AGGLOMERATION}/*`,
        element: (
          <PrivateRoute
            element={
              <Suspense>
                <PageAgglomeration />
              </Suspense>
            }
            requiredAccess={ACCESS_GROUPS.AGGLOMERATION}
          />
        ),
      },
      {
        path: `${ROUTES.TPU}/*`,
        element: (
          <PrivateRoute
            element={
              <Suspense>
                <PageTPU />
              </Suspense>
            }
            requiredAccess={ACCESS_GROUPS.TPU}
          />
        ),
      },

      {
        path: ROUTES.UI_KIT,
        element: (
          <PrivateRoute
            element={
              <Suspense>
                <UIKit />
              </Suspense>
            }
          />
        ),
      },
    ],
  },
]);
