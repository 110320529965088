import Keycloak from 'keycloak-js';

export const keycloak = new Keycloak({
  url: import.meta.env.APP_AUTH_URL,
  realm: import.meta.env.APP_AUTH_REALM,
  clientId: import.meta.env.APP_AUTH_CLIENT_ID,
});
//
// keycloak.onTokenExpired = () => {
//   keycloak.updateToken(30)
//     .then(refreshed => {
//       if (refreshed) {
//       } else {
//         console.warn('Token not refreshed, you need to log in again');
//       }
//     })
//     .catch(err => {
//       console.error('Failed to refresh token', err);
//     });
// };
