import { ApolloLink, HttpLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { AGGLOMERATE_CLIENT_NAME, TPU_CLIENT_NAME } from '@constants/api';

import { keycloak } from '@system/keycloak/keycloak';

import { checkAuthToken } from '@utils/checkAuthToken';
import { createApolloClient } from '@utils/createApolloClient';
import { showErrorHandler } from '@utils/showErrorHandler';

const DEFAULT_ENDPOINT = new HttpLink({
  uri: import.meta.env.APP_API_ENDPOINT,
});
const TPU_ENDPOINT = createUploadLink({
  uri: import.meta.env.APP_API_TPU_ENDPOINT,
});

const AGGLOMERATE_ENDPOINT = createUploadLink({
  uri: import.meta.env.APP_API_AG_ENDPOINT,
});

/**
 * @todo переехать на supergraph, если это возможно
 * */
export const apiClient = createApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().clientName === TPU_CLIENT_NAME,
    TPU_ENDPOINT,
    ApolloLink.split(
      operation => operation.getContext().clientName === AGGLOMERATE_CLIENT_NAME,
      AGGLOMERATE_ENDPOINT,
      DEFAULT_ENDPOINT,
    ),
  ),
  showErrorHandler,

  headers: () => ({
    // 'X-Auth': `Bearer ${keycloak.token || ''}`,
    // 'X-Csrftoken': 'Wq66GUMsdEcTd52RSbpDLOBPBtVLbEXd',
    // Тут указываем кастомфыне заголовки если нужно
    'X-Auth': `Bearer ${keycloak.token || ''}`,
  }),
  credentials: 'include',
  unauthorizedCallback: checkAuthToken,
});

/*
Использование в Effect`ax

apiClient.query({
  query: gql``
})

Использование в компонентах черех хук

 */
