import { components } from './overrides';
import { palette } from './palette';

import { createTheme } from '@mui/material';

export const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 1200,
      lg: 1536,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Verdana',
    subtitle1: {
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 700,
    },
  },
  palette,
  components,
});
