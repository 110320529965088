import { dialogActionsClasses, dialogClasses, dialogContentClasses, dialogTitleClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`& .${dialogClasses.paper}`]: {
        width: theme.typography.pxToRem(640),
      },
      [`& .${dialogTitleClasses.root}`]: {
        padding: theme.spacing(4),
      },
      [`& .${dialogContentClasses.root}`]: {
        padding: theme.spacing(0, 4, 4),
      },
      [`& .${dialogActionsClasses.root}`]: {
        padding: theme.spacing(4),
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    }),
  },
};
