import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import LogoImg from '@assets/logo.svg';

import { Logout } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { HeaderMenu } from '@components/header/menu';
import { WrapLogo } from '@components/header/styles';

import { APP } from '@constants/app';
import { ROUTES } from '@constants/routes';

import { useCustomNavigate } from '@utils/NavigationBlockContext/useCustomNavigate';

export const Header = () => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const { keycloak } = useKeycloak();
  const navigate = useCustomNavigate();
  const location = useLocation();

  const userName = `${keycloak.tokenParsed?.given_name || ' '} ${
    keycloak.tokenParsed?.family_name ? keycloak.tokenParsed?.family_name[0] : ''
  }`;

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleLogout = () => {
    keycloak.logout();
  };

  const handleNavigateToMain = () => {
    navigate(ROUTES.MAIN);
  };

  const handleTitle = () => {
    const firstSlashIndex = location.pathname.indexOf('/');
    const secondSlashIndex = location.pathname.indexOf('/', firstSlashIndex + 1);
    let path = '';

    if (secondSlashIndex !== -1) {
      path = location.pathname.substring(0, secondSlashIndex);
    } else {
      path = location.pathname;
    }

    switch (path) {
      case ROUTES.PASSENGER_TRAFFIC:
        return APP.TITLE_PT;
      case ROUTES.AGGLOMERATION:
        return APP.TITLE_AG;
      case ROUTES.TPU:
        return APP.TITLE_TPU;
    }

    return APP.TITLE_MAIN;
  };

  return (
    <AppBar
      position="static"
      sx={{
        height: 80,
        justifyContent: 'center',
        flexWrap: 'nowrap',
        px: 2.75,
      }}
      elevation={0}
    >
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        flexWrap={'nowrap'}
        sx={{ zIndex: 2 }}
      >
        <Grid
          display="flex"
          alignItems={'center'}
          flexWrap={'nowrap'}
        >
          <Link
            onClick={handleNavigateToMain}
            style={{
              transform: 'skew(-35deg)',
              marginRight: '40px',
              cursor: 'pointer',
            }}
          >
            <WrapLogo>
              <img
                src={LogoImg}
                alt="Лого"
              />
            </WrapLogo>
          </Link>
          <Typography
            variant={'h5'}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {/* {APP.TITLE} */}
            {handleTitle()}
          </Typography>
        </Grid>

        <Grid
          display="flex"
          alignItems={'center'}
        >
          <Grid
            container
            display="flex"
            alignItems={'center'}
            flexWrap={'nowrap'}
            gap={4}
          >
            <HeaderMenu />

            {/* <Typography whiteSpace={'nowrap'}>База данных</Typography> */}

            <Divider
              sx={{ borderColor: 'rgba(255, 255, 255, 0.10)' }}
              orientation={'vertical'}
              flexItem
            />

            <Menu
              anchorEl={anchorMenu}
              id="account-menu"
              open={!!anchorMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Выйти
              </MenuItem>
            </Menu>

            <Grid
              display="flex"
              alignItems={'center'}
              wrap={'nowrap'}
              gap={2}
            >
              <Button
                onClick={handleClick}
                size="small"
                variant={'text'}
                aria-controls={anchorMenu ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorMenu ? 'true' : undefined}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                >
                  <Typography
                    whiteSpace={'nowrap'}
                    sx={theme => ({
                      color: theme.palette.common.white,
                    })}
                  >
                    {userName}
                  </Typography>
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {keycloak.tokenParsed?.name ? keycloak.tokenParsed.name[0].toUpperCase() : ''}
                  </Avatar>
                </Stack>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};
