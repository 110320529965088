export const ROUTES = {
  GENERAL: '/',
  AUTH: '/',
  MAIN: '/module',
  UI_KIT: '/ui-kit',
  PASSENGER_TRAFFIC: '/passenger-traffic',
  PASSENGER_TRAFFIC_CONSTRUCTOR: '/passenger-traffic/constructor',
  PASSENGER_TRAFFIC_FORECAST: '/passenger-traffic/forecast',
  PASSENGER_TRAFFIC_DASHBOARD: '/passenger-traffic/dashboard',
  AGGLOMERATION: '/agglomeration',
  AGGLOMERATION_PROJECT: '/agglomeration/project',
  AGGLOMERATION_CONSTRUCTOR: '/agglomeration/constructor',
  AGGLOMERATION_FORECAST: '/agglomeration/forecast',
  TPU: '/tpu',
  TPU_PASSENGER_TYPES: '/tpu/passenger-types',
  TPU_SCENARIOS: '/tpu/scenarios',
  TPU_SCENARIOS_VIEWER: '/viewer',
  TPU_EDITOR: '/tpu/editor',
  TPU_TRAIN_SCHEDULE: '/tpu/train-schedule',
};
